import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order-confirm-box"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    attrs: {
      "headerTit": "订单结算",
      "inputWidth": "370"
    }
  }), _vm.userIdentity === 2 ? _c('div', {
    staticClass: "order-on-behalf-of-customers"
  }, [_c('p', {
    staticClass: "order-title"
  }, [_vm._v("选择客户")]), _c('ul', [_c('li', [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.name,
      expression: "userInfo.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "点击查询客户"
    },
    domProps: {
      "value": _vm.userInfo.name
    },
    on: {
      "focus": _vm.showInfoList,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "name", $event.target.value);
      }
    }
  })]), _c('li', [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.num,
      expression: "userInfo.num"
    }],
    attrs: {
      "type": "text",
      "placeholder": "自动识别",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userInfo.num
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "num", $event.target.value);
      }
    }
  })]), _c('li', [_vm._m(2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInfo.address,
      expression: "userInfo.address"
    }],
    attrs: {
      "type": "text",
      "placeholder": "自动识别",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userInfo.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userInfo, "address", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.userIdentity === 1 || _vm.userInfo.num ? _c('div', {
    staticClass: "shipping-address-box"
  }, [_c('div', {
    staticClass: "shipping-address-title"
  }, [_c('p', [_vm._v("选择收货地址")]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userIdentity === 1,
      expression: "userIdentity === 1"
    }],
    on: {
      "click": _vm.toAddressList
    }
  }, [_c('img', {
    attrs: {
      "src": require("./image/icon_edit.png"),
      "alt": ""
    }
  }), _vm._v(" 管理收货地址 ")])]), _c('div', {
    staticClass: "address-list"
  }, [!_vm.addressList.length ? _c('div', {
    staticClass: "no-address"
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userIdentity === 1,
      expression: "userIdentity === 1"
    }],
    attrs: {
      "src": require("./image/icon_add.png"),
      "alt": ""
    },
    on: {
      "click": _vm.toAddressList
    }
  }), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userIdentity === 1,
      expression: "userIdentity === 1"
    }],
    on: {
      "click": _vm.toAddressList
    }
  }, [_vm._v("添加收货地址")]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userIdentity === 2,
      expression: "userIdentity === 2"
    }],
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_vm._v(" 暂无收货地址 ")])]) : _c('div', {
    staticClass: "has-address"
  }, [_c('div', {
    class: !_vm.isMore ? 'has-address-box' : 'has-address-box is-more'
  }, _vm._l(_vm.addressList, function (item, index) {
    return _c('div', {
      key: index,
      class: _vm.addressId === item.id ? 'list-box list-box-default' : 'list-box',
      on: {
        "click": function click($event) {
          return _vm.getAddressId(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "top-info"
    }, [_c('p', [_vm._v(_vm._s(item.provinceName) + _vm._s(item.cityName))]), _c('p', [_vm._v(" ( "), _c('span', [_vm._v(_vm._s(item.consignee))]), _vm._v(" )收 ")])]), _c('div', {
      staticClass: "address-info"
    }, [_vm._v(" " + _vm._s(item.districtName) + _vm._s(item.addreDetail) + " ")]), _c('div', {
      staticClass: "phone"
    }, [_vm._v(_vm._s(item.consigneePhone))]), _vm.addressId === item.id && _vm.userIdentity === 1 ? _c('div', {
      staticClass: "edit",
      on: {
        "click": _vm.toAddressList
      }
    }, [_vm._v(" 修改 ")]) : _vm._e(), _vm.userIdentity === 1 ? _c('div', {
      class: _vm.addressId === item.id ? 'default-tag default-tag-true' : 'default-tag',
      on: {
        "click": function click($event) {
          return _vm.toDefault(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.defaultStatus ? "默认地址" : "设为默认") + " ")]) : _vm._e(), _vm.userIdentity === 2 && item.defaultStatus ? _c('div', {
      staticClass: "default-tag default-tag-true"
    }, [_vm._v(" 默认地址 ")]) : _vm._e()]);
  }), 0), _vm.addressList.length > 3 ? _c('p', {
    staticClass: "more",
    on: {
      "click": _vm.toLookMore
    }
  }, [_vm.isMore ? _c('i', {
    staticClass: "fa fa-caret-up",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-caret-down",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.isMore ? "收起" : "更多") + " ")]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "product-list-box"
  }, [_c('div', {
    staticClass: "product-list-title"
  }, [_c('p', [_vm._v("确认订单信息")]), _c('p', {
    on: {
      "click": function click($event) {
        _vm.imageAndList = !_vm.imageAndList;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.imageAndList ? "列表展示" : "图文展示") + " ")])]), _vm.imageAndList ? _c('div', {
    class: _vm.shoppList.length >= 5 ? 'product-list product-list-scroll' : 'product-list'
  }, [_vm._m(3), _c('div', {
    staticClass: "shopp-product-list-box"
  }, _vm._l(_vm.shoppList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "shopp-product"
    }, [_c('div', {
      staticClass: "product-info"
    }, [_c('div', {
      staticClass: "product-info-main"
    }, [_c('div', {
      staticClass: "product-img-box"
    }, [_c('div', {
      staticClass: "product-img"
    }, [_c('img', {
      attrs: {
        "src": item.productPhoto,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "product-introduce"
    }, [_c('p', [_vm._v("【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName))]), _c('p', [_vm._v(_vm._s(item.produceFactory))]), item.nearEffectivePeriod ? _c('p', [_vm._v(" 效期 " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + " ")]) : _c('p', [_vm._v("效期")])])]), item.editor ? _c('div', {
      staticClass: "special-offer"
    }, [_c('p', [_vm._v("活动")]), _c('p', [_vm._v(_vm._s(item.editor))])]) : _vm._e()]), _c('div', {
      staticClass: "product-price"
    }, [item.superDiscountProductPrice ? _c('p', {
      staticClass: "tj-product-price"
    }, [_c('span', [_vm._v("特价")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("NumFormat")(Number(item.superDiscountProductPrice))) + " "), item.sellUnit ? _c('span', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()])]) : _vm._e(), item.superDiscountProductPrice ? _c('p', {
      staticClass: "tj-oldPrice"
    }, [_vm._v(" " + _vm._s(_vm._f("NumFormat")(Number(item.preferentialPrice))) + " "), item.sellUnit ? _c('span', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()]) : _c('p', [_vm._v(" ¥" + _vm._s(_vm._f("NumFormat")(Number(item.preferentialPrice))) + " "), item.sellUnit ? _c('span', [_vm._v("/" + _vm._s(item.sellUnit))]) : _vm._e()])]), _c('div', {
      staticClass: "product-num"
    }, [_c('p', [_vm._v(" X" + _vm._s(item.totalNumber) + " "), item.tipsStr ? _c('span', [_vm._v("中包装")]) : _vm._e()]), item.tipsStr ? _c('span', {
      staticClass: "num"
    }, [_vm._v("(实际购买" + _vm._s(_vm._f("unit")(item.tipsStr)) + _vm._s(item.sellUnit) + ")")]) : _vm._e()]), _c('div', {
      staticClass: "product-subtotal"
    }, [_c('p', [_vm._v("¥" + _vm._s(Number(item.subtotalMoney).toFixed(2)))]), item.preferentialTotalMoney ? _c('p', [_vm._v(" 优惠¥" + _vm._s(_vm._f("NumFormat")(Number(item.preferentialTotalMoney))) + " ")]) : _vm._e()])]);
  }), 0)]) : _vm._e(), !_vm.imageAndList ? _c('div', {
    class: _vm.shoppList.length >= 10 ? 'product-list-exhibition product-list-scroll' : 'product-list-exhibition'
  }, [_vm._m(4), _vm._l(_vm.shoppList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "list-data"
    }, [_c('p', {
      attrs: {
        "title": item.productName
      }
    }, [_vm._v(_vm._s(item.productName))]), _c('p', {
      attrs: {
        "title": item.specifications
      }
    }, [_vm._v(_vm._s(item.specifications))]), _c('p', {
      attrs: {
        "title": item.produceFactory
      }
    }, [_vm._v(_vm._s(item.produceFactory))]), _c('p', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)) + "/" + _vm._s(_vm._f("formatDate")(item.farEffectivePeriod)) + " ")]), _c('p', {
      attrs: {
        "title": item.unitPrice
      }
    }, [_vm._v(_vm._s(item.unitPrice))]), _c('p', {
      attrs: {
        "title": item.sellUnit
      }
    }, [_vm._v(_vm._s(item.sellUnit))]), _c('p', {
      attrs: {
        "title": item.totalNumber
      }
    }, [_vm._v(" " + _vm._s(item.totalNumber) + " "), _c('span', [_vm._v(_vm._s(item.tipsStr))])]), _c('p', {
      attrs: {
        "title": item.reducedPrice
      }
    }, [_vm._v(" " + _vm._s(_vm._f("NumFormat")(Number(item.preferentialTotalMoney))) + " ")])]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "empty"
  })]), _c('div', {
    staticClass: "model-box-ball"
  }, [_vm._m(5), _c('div', {
    staticClass: "pay-list-box"
  }, [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info"
    },
    model: {
      value: _vm.isgivePhone,
      callback: function callback($$v) {
        _vm.isgivePhone = $$v;
      },
      expression: "isgivePhone"
    }
  }), _vm._v(" " + _vm._s(_vm.isgivePhone ? "是" : "否") + " ")], 1)]), _c('div', {
    staticClass: "model-box-ball"
  }, [_vm._m(6), _c('div', {
    staticClass: "balance-list-box",
    staticStyle: {
      "flex-flow": "wrap"
    }
  }, [Number(_vm.totalBalanceAmountdd) > 0 ? [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info",
      "disabled": Number(_vm.balanceAmount) === 0
    },
    on: {
      "input": _vm.isBalanceChange
    },
    model: {
      value: _vm.isBalance,
      callback: function callback($$v) {
        _vm.isBalance = $$v;
      },
      expression: "isBalance"
    }
  }), _c('span', {
    staticClass: "balance"
  }, [_vm._v("立即使用红包:" + _vm._s(_vm._f("NumFormat")(Number(_vm.balanceAmount))) + "元")]), _vm.isBalance ? _c('span', {
    staticClass: "balance23",
    staticStyle: {
      "color": "#f45757",
      "margin-left": "20px"
    }
  }, [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.balanceAmount))))]) : _vm._e(), _c('div', {
    staticClass: "balance1",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" 账户余额:" + _vm._s(_vm._f("NumFormat")(Number(_vm.totalBalanceAmountdd))) + "元("), _c('span', {
    staticStyle: {
      "font-size": "13px",
      "color": "#666"
    }
  }, [_vm._v("余额可最高抵减应付货款的50%")]), _vm._v(") ")]), Number(_vm.redpage) > 0 ? _c('div', {
    staticClass: "balance1",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" 本次订单预计赠送红包("), _c('span', {
    staticStyle: {
      "font-size": "13px",
      "color": "#666"
    }
  }, [_vm._v("以实际发货数量为准，每个红包0.5元")]), _vm._v("): "), _c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("./image/yue.png")
    }
  }), _c('font', {
    staticStyle: {
      "color": "#f45757"
    }
  }, [_vm._v("x" + _vm._s(_vm.redpage) + "个")])], 1) : _vm._e()] : [_c('div', {
    staticClass: "balance1",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("账户余额为0不可用")])]], 2)]), _c('div', {
    staticClass: "model-box-ball"
  }, [_vm._m(7), _c('div', {
    staticClass: "balance-list-box",
    staticStyle: {
      "flex-flow": "wrap"
    }
  }, [_vm.checkCouponStatus ? [_vm.checkCouponStatus == '1' ? _c('div', [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info"
    },
    on: {
      "input": _vm.isCouponChange
    },
    model: {
      value: _vm.useCoupon,
      callback: function callback($$v) {
        _vm.useCoupon = $$v;
      },
      expression: "useCoupon"
    }
  }), _c('span', {
    staticClass: "balance"
  }, [_vm._v("立即使用优惠券:" + _vm._s(_vm.couponCurrentDeductionPrice) + "元")])], 1) : _vm._e(), _vm.checkCouponStatus == '1' && _vm.couponCurrentDeductionPrice == 5 ? _c('div', [_vm._v("（再添加" + _vm._s(_vm.couponNeedAddPrice) + "元品种，本次可抵扣" + _vm._s(_vm.couponNextDeductionPrice) + "元）")]) : _vm._e(), _vm.checkCouponStatus == '0' ? _c('div', {
    staticClass: "balance1",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("订单未满足99，当前（" + _vm._s(_vm.couponCurrentDeductionPrice) + "元）优惠券不可用")]) : _vm._e()] : [_c('div', {
    staticClass: "balance1",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("无优惠券可用")])]], 2)]), _vm.lstPayType.length > 0 ? _c('div', {
    staticClass: "model-box-ball"
  }, [_c('p', [_vm.onlinePaymentPercentage != null && !_vm.whetherCashCustomer ? _c('b-tooltip', {
    attrs: {
      "label": '活动期间，线上支付立减' + _vm.onlinePaymentPercentage + '‰',
      "always": "",
      "position": "is-right"
    }
  }, [_c('span', [_vm._v("选择支付方式")])]) : _vm.onlinePaymentPercentage != null && _vm.whetherCashCustomer ? _c('b-tooltip', {
    attrs: {
      "label": '活动期间，线上支付立减' + _vm.onlinePaymentPercentage + '‰，' + '*线下支付、线上支付：完成支付后再发货。',
      "always": "",
      "position": "is-right"
    }
  }, [_c('span', [_vm._v("选择支付方式")])]) : _vm.onlinePaymentPercentage == null && _vm.whetherCashCustomer ? _c('b-tooltip', {
    attrs: {
      "label": "*线上支付：完成支付后再发货。",
      "always": "",
      "position": "is-right"
    }
  }, [_c('span', [_vm._v("选择支付方式")])]) : _c('span', [_vm._v("选择支付方式")])], 1), _c('div', {
    staticClass: "pay-list-box"
  }, _vm._l(_vm.lstPayType, function (item, index) {
    return _c('div', {
      key: index,
      class: _vm.payKey === item.payType ? 'activePay' : '',
      on: {
        "click": function click($event) {
          return _vm.clickPayType(item.payType);
        }
      }
    }, [_vm._v(" " + _vm._s(item.payTypeName) + " ")]);
  }), 0), _vm.maxlimitAmt != null && _vm.maxlimitAmt != '' ? _c('div', {
    staticClass: "maxlimitAmt_box"
  }, [_c('span', [_vm._v("!")]), _c('label', [_vm._v(_vm._s(_vm.maxlimitAmt))])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "model-box-ball"
  }, [_c('p', [_vm._v(" 选择配送方式 "), _vm.giveKey === '2' || _vm.giveKey === '3' ? _c('span', [_vm._v(" 线下结算 ")]) : _c('span', [_vm._v(" (运费 "), _c('a', [_vm._v("¥" + _vm._s(_vm.freightNum))]), _vm._v(" ) ")])]), _c('div', {
    staticClass: "pay-list-box"
  }, _vm._l(_vm.giveList, function (item, index) {
    return _c('div', {
      key: index,
      class: _vm.giveKey === item.key ? 'activeGive' : '',
      on: {
        "click": function click($event) {
          return _vm.clickGiveType(item.key);
        }
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)]), _vm.userIdentity === 1 || _vm.userInfo.num ? _c('div', {
    staticClass: "invoice-box"
  }, [_c('div', {
    staticClass: "invoice-title"
  }, [_c('p', [_c('b-checkbox', {
    attrs: {
      "size": "is-small",
      "type": "is-info"
    },
    on: {
      "input": function input($event) {
        return _vm.getInvoice($event);
      }
    },
    model: {
      value: _vm.isInvoice,
      callback: function callback($$v) {
        _vm.isInvoice = $$v;
      },
      expression: "isInvoice"
    }
  }), _vm._v("开具发票（可补开） ")], 1), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userIdentity === 1,
      expression: "userIdentity === 1"
    }],
    on: {
      "click": _vm.toInvoiceList
    }
  }, [_c('img', {
    attrs: {
      "src": require("./image/icon_edit.png"),
      "alt": ""
    }
  }), _vm._v("管理发票抬头 ")])]), _c('div', {
    staticClass: "invoice-select"
  }, [_c('div', [_c('p', [_vm._v("发票抬头")]), _c('b-select', {
    attrs: {
      "disabled": !_vm.isInvoice,
      "placeholder": "请选择",
      "expanded": ""
    },
    on: {
      "input": _vm.getInvoiceTitle
    },
    model: {
      value: _vm.invoiceForm.invoiceTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceForm, "invoiceTitle", $$v);
      },
      expression: "invoiceForm.invoiceTitle"
    }
  }, _vm._l(_vm.invoiceList, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 0)], 1), _c('div', [_c('p', [_vm._v("纳税人识别号")]), _c('b-input', {
    attrs: {
      "disabled": "",
      "placeholder": "自动识别"
    },
    model: {
      value: _vm.invoiceForm.num,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceForm, "num", $$v);
      },
      expression: "invoiceForm.num"
    }
  })], 1), _c('div', [_c('p', [_vm._v("开票方式")]), _c('b-radio', {
    attrs: {
      "disabled": !_vm.isInvoice,
      "name": "name",
      "native-value": "1"
    },
    model: {
      value: _vm.invoiceForm.invoiceType,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceForm, "invoiceType", $$v);
      },
      expression: "invoiceForm.invoiceType"
    }
  }, [_vm._v("电子发票")]), _c('b-radio', {
    attrs: {
      "disabled": !_vm.isInvoice,
      "name": "name",
      "native-value": "2"
    },
    model: {
      value: _vm.invoiceForm.invoiceType,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceForm, "invoiceType", $$v);
      },
      expression: "invoiceForm.invoiceType"
    }
  }, [_vm._v("纸质发票")])], 1), _c('div', {
    staticClass: "remarks"
  }, [_c('p', [_vm._v("备注")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.invoiceForm.remarks,
      expression: "invoiceForm.remarks"
    }],
    staticClass: "textarea-box",
    attrs: {
      "disabled": !_vm.isInvoice,
      "maxlength": "200"
    },
    domProps: {
      "value": _vm.invoiceForm.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.invoiceForm, "remarks", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _c('div', {
    staticClass: "total-amount"
  }, [_c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v(_vm._s(_vm.totalForm.productNum) + "件商品费用")]), _c('p', [_vm._v("¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.total))))])]), _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("满减")]), _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.ticketDiscount))))])]), _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("余额抵扣")]), _vm.isBalance ? _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.balanceAmount))))]) : _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(0)))])]), _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("运费")]), _c('p', [_vm._v("¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.freight))))])]), Number(_vm.totalForm.rebateMoney) > 0 ? _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("返利折扣")]), _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.rebateMoney))))])]) : _vm._e(), _vm.totalForm.cashAmount ? _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("现金返点")]), _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.cashAmount))))])]) : _vm._e(), _vm.useCoupon == '1' ? _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("优惠券抵扣")]), _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.couponCurrentDeductionPrice))))])]) : _vm._e(), _c('div', {
    staticClass: "label-box"
  }, [_c('p', [_vm._v("线上支付优惠")]), _c('p', [_vm._v("-¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.onlinePaymentInstantDiscount))))])]), _c('div', {
    staticClass: "amount-box"
  }, [_c('p', [_vm._v("费用合计")]), _c('p', [_vm._v("¥ " + _vm._s(_vm._f("NumFormat")(Number(_vm.totalForm.allTotal))))])]), _c('div', {
    staticClass: "button-box"
  }, [_c('p', {
    on: {
      "click": function click($event) {
        return _vm.toBack(0);
      }
    }
  }, [_vm._v("返回购物车")]), _c('b-button', {
    attrs: {
      "type": "is-info",
      "disabled": _vm.disSubmit
    },
    on: {
      "click": _vm.toPay
    }
  }, [_vm._v(" " + _vm._s(_vm.payKey === "OFFLINE" ? "提交订单" : "立即付款") + " ")])], 1)]), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "inner-dialog"
        }, [_c('h4', {
          staticClass: "groupTitle"
        }, [_c('span'), _c('button', {
          staticClass: "delete",
          attrs: {
            "type": "button"
          },
          on: {
            "click": props.close
          }
        })]), _c('div', {
          staticClass: "groupCont"
        }, [_c('div', {
          staticClass: "search"
        }, [_vm._v(" 查询条件："), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.userName,
            expression: "userName"
          }],
          attrs: {
            "type": "text",
            "placeholder": "输入客户编码/客户名称/联系人",
            "name": "",
            "id": ""
          },
          domProps: {
            "value": _vm.userName
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.userName = $event.target.value;
            }
          }
        }), _c('b-button', {
          attrs: {
            "type": "is-info"
          },
          on: {
            "click": _vm.searchName
          }
        }, [_vm._v("查询")]), _c('b-button', {
          attrs: {
            "type": "is-info reset"
          },
          on: {
            "click": _vm.resetName
          }
        }, [_vm._v("重置")])], 1), _vm.userList.length ? _c('div', {
          staticClass: "infoList"
        }, _vm._l(_vm.userList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "infoContent"
          }, [_c('label', {
            attrs: {
              "for": item.companyId
            }
          }, [_c('input', {
            attrs: {
              "type": "checkbox",
              "name": "user",
              "id": item.companyId
            },
            domProps: {
              "checked": item.checked
            },
            on: {
              "click": function click($event) {
                return _vm.selectUser(item);
              }
            }
          }), _vm._v(" " + _vm._s(item.account) + " ")]), _c('p', {
            staticClass: "companyName"
          }, [_vm._v(_vm._s(item.name))]), _c('p', [_vm._v(_vm._s(item.contactsName))])]);
        }), 0) : _vm._e(), _vm.userList.length === 0 ? _c('div', {
          staticClass: "noInfoList"
        }, [_c('div', {
          staticClass: "empty-list"
        }, [_c('img', {
          attrs: {
            "src": require("../../user-center/order/images/pic_empty.png"),
            "alt": ""
          }
        }), _c('span', [_vm._v("暂无数据")])])]) : _vm._e()]), _vm.userList.length === 0 ? _c('div', {
          staticClass: "noPanigation"
        }) : _vm._e(), _vm.userList.length > 0 ? _c('div', {
          staticClass: "pagination",
          staticStyle: {
            "width": "100%",
            "justify-content": "flex-end",
            "margin-left": "0px"
          }
        }, [_c('b-pagination', {
          staticClass: "is-centered",
          attrs: {
            "total": _vm.total,
            "current": _vm.current,
            "per-page": _vm.perPage,
            "range-before": _vm.rangeBefore,
            "range-after": _vm.rangeAfter
          },
          on: {
            "update:current": function updateCurrent($event) {
              _vm.current = $event;
            },
            "change": _vm.handleChange
          }
        })], 1) : _vm._e(), _c('div', {
          staticClass: "footer"
        }, [_c('b-button', {
          attrs: {
            "type": "concel"
          },
          on: {
            "click": props.close
          }
        }, [_vm._v("取消")]), _c('b-button', {
          attrs: {
            "type": "apply"
          },
          on: {
            "click": _vm.sureSelect
          }
        }, [_vm._v(" 确定 ")])], 1)])];
      }
    }]),
    model: {
      value: _vm.isShowInfoList,
      callback: function callback($$v) {
        _vm.isShowInfoList = $$v;
      },
      expression: "isShowInfoList"
    }
  }), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "inner-dialog inner-dialog-balance"
        }, [_c('div', [_c('h4', {
          staticClass: "groupTitle"
        }, [_c('span'), _c('button', {
          staticClass: "delete",
          attrs: {
            "type": "button"
          },
          on: {
            "click": props.close
          }
        })]), _c('div', {
          staticClass: "groupCont balanceCont"
        }, [_vm._v(" " + _vm._s(_vm.balanceMsg) + " ")]), _c('div', {
          staticClass: "footer"
        }, [_c('b-button', {
          attrs: {
            "type": "concel"
          },
          on: {
            "click": props.close
          }
        }, [_vm._v("取消")]), _c('b-button', {
          attrs: {
            "type": "apply"
          },
          on: {
            "click": _vm.sureBalance
          }
        }, [_vm._v(" 确定 ")])], 1)])])];
      }
    }]),
    model: {
      value: _vm.isBalanceFlag,
      callback: function callback($$v) {
        _vm.isBalanceFlag = $$v;
      },
      expression: "isBalanceFlag"
    }
  }), _c('b-modal', {
    attrs: {
      "active": _vm.orderProgress,
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Example Modal",
      "close-button-aria-label": "Close",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "inner-dialog inner-dialog-balance",
          staticStyle: {
            "border-radius": "10px"
          }
        }, [_c('div', {
          staticClass: "proBox"
        }, [_c('div', {
          staticClass: "proBox-title"
        }, [_c('span', [_vm._v("创建订单")])]), _c('div', {
          staticClass: "proBox-msg"
        }, [_vm._v(" 订单正在创建中...... ")]), _c('b-progress', {
          attrs: {
            "type": "is-success",
            "value": _vm.orderPercent,
            "show-value": "",
            "format": "percent"
          }
        }), _c('div', {
          staticClass: "proBox-msg-des"
        }, [_c('p', [_vm._v("由于商品售卖较为火爆，")]), _c('p', [_vm._v("正在为您校验商品库存，请稍等....")])])], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "active": _vm.isErrorShow,
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Example Modal",
      "close-button-aria-label": "Close",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "inner-dialog inner-dialog-balance",
          staticStyle: {
            "border-radius": "10px"
          }
        }, [_vm.errorBox ? _c('div', {
          staticClass: "proBox",
          staticStyle: {
            "height": "240px"
          }
        }, [_c('div', {
          staticClass: "proBox-title"
        }, [_c('span', [_vm._v("温馨提示")])]), _c('div', {
          staticClass: "errorMsg"
        }, [_c('h3', {
          staticClass: "errorMsg-title"
        }, [_vm._v("尊敬的普健用户您好：")]), _vm.isMeet ? _c('div', {
          staticClass: "errorMsg-content"
        }, [_vm._v(" " + _vm._s(_vm.orderErrorMsg) + " ")]) : _vm._e(), !_vm.isMeet ? _c('div', {
          staticClass: "errorMsg-content",
          staticStyle: {
            "text-indent": "0"
          }
        }, [_vm._v("当前客户为现金托运客户"), _c('br'), _vm.isMeetTwo ? _c('span', [_vm._v("由于价格或库存变动，")]) : _vm._e(), _vm._v("支付金额需满足299元，请返回购物车加购或变更其他客户进行下单。")]) : _vm._e(), _c('div', {
          staticClass: "errorMsg-enter"
        }, [_vm.isMeet ? _c('b-button', {
          attrs: {
            "type": "is-info"
          },
          on: {
            "click": function click($event) {
              return _vm.toBack(1, '', 0);
            }
          }
        }, [_vm._v("确定")]) : _vm._e(), _c('div', {
          staticClass: "errorMsg-enter-btns"
        }, [!_vm.isMeet ? _c('b-button', {
          attrs: {
            "type": "is-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.toBack(1, '', 0);
            }
          }
        }, [_vm._v("返回购物车")]) : _vm._e(), !_vm.isMeet ? _c('b-button', {
          attrs: {
            "type": "is-info"
          },
          on: {
            "click": _vm.cheangeKehu
          }
        }, [_vm._v("变更客户")]) : _vm._e()], 1)], 1)])]) : _vm._e(), !_vm.errorBox ? _c('div', {
          staticClass: "proBox",
          staticStyle: {
            "height": "681px"
          }
        }, [_c('div', {
          staticClass: "proBox-title"
        }, [_c('span', [_vm._v("温馨提示")])]), _c('div', {
          staticClass: "errorMsg"
        }, [_c('h3', {
          staticClass: "errorMsg-title"
        }, [_vm._v("尊敬的普健用户您好：")]), _c('div', {
          staticClass: "errorMsg-content"
        }, [_vm._v(" " + _vm._s(_vm.errorData.isCondition == 1 ? "由于价格或库存变动，当前订单金额已不满足299元，请点击下方“重新选购”按钮返回购物车。" : "由于市场价格与库存实时波动，已为您同步最新价格和库存。") + " ")]), _c('div', {
          staticClass: "errorMsg-list"
        }, [_c('div', {
          staticClass: "errorMsg-list-box"
        }, [_c('div', {
          staticClass: "errorMsg-list-box-item"
        }, [_c('div', {
          staticClass: "item-width"
        }, [_vm._v("商品名称")]), _c('div', {
          staticClass: "item-width"
        }, [_vm._v("原下单数")]), _c('div', {
          staticClass: "item-width"
        }, [_vm._v("商品库存")]), _c('div', {
          staticClass: "item-width"
        }, [_vm._v("原单价")]), _c('div', {
          staticClass: "item-width"
        }, [_vm._v("现单价")])]), _vm._l(_vm.errorData.abnormalOrderForms, function (item, eindex) {
          return _c('div', {
            key: eindex,
            staticClass: "errorMsg-list-box-item box-item-list"
          }, [_c('div', {
            staticClass: "item-width item-width-bg"
          }, [_vm._v(_vm._s(item.productName))]), _c('div', {
            staticClass: "item-width item-width-bg"
          }, [_vm._v(_vm._s(item.oldqty))]), item.lx != 1 ? _c('div', {
            staticClass: "item-width item-width-bg"
          }, [_vm._v(_vm._s(item.newqty))]) : _vm._e(), item.lx != 1 ? _c('div', {
            staticClass: "item-width item-width-bg"
          }, [_vm._v("¥" + _vm._s(item.oldprice))]) : _vm._e(), item.lx != 1 ? _c('div', {
            staticClass: "item-width item-width-bg"
          }, [_vm._v("¥" + _vm._s(item.newprice))]) : _vm._e(), item.lx == 1 ? _c('div', {
            staticStyle: {
              "width": "60%",
              "text-align": "center",
              "font-size": "14px",
              "background-color": "#ffffff"
            }
          }, [_vm._v("该商品低于进货价，无法售卖")]) : _vm._e()]);
        })], 2)]), _c('div', {
          staticClass: "errorMsg-bottom"
        }, [_c('div', {
          staticClass: "errorMsg-price"
        }, [_c('p', [_vm._v("红包抵扣")]), _c('div', {
          staticClass: "errorMsg-price-item"
        }, [_c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', [_vm._v("原单可抵扣：¥" + _vm._s(_vm.errorData.oldBalanceAmount))])]), _c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', [_vm._v("现单可抵扣：¥" + _vm._s(_vm.errorData.balanceAmount))])])])]), _c('div', {
          staticClass: "errorMsg-price"
        }, [_c('p', [_vm._v("满减优惠")]), _c('div', {
          staticClass: "errorMsg-price-item"
        }, [_c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', [_vm._v("原单优惠：¥" + _vm._s(_vm.errorData.oldTotalReductionAmount))])]), _c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', [_vm._v("现单优惠：¥" + _vm._s(_vm.errorData.totalReductionAmount))])])])]), _c('div', {
          staticClass: "errorMsg-price"
        }, [_c('p', [_vm._v("原订单金额：¥" + _vm._s(_vm.errorData.oldRealPayAmount))]), _c('div', {
          staticClass: "errorMsg-price-item"
        }, [_c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', {
          staticClass: "nowPrice"
        }, [_vm._v("现订单金额："), _c('span', {
          staticStyle: {
            "color": "#FF2972"
          }
        }, [_vm._v("¥" + _vm._s(_vm.errorData.realPayAmount))])])]), _vm.errorData.cashRebate >= 0 ? _c('div', {
          staticClass: "errorMsg-price-item-le"
        }, [_c('span', [_vm._v("现金反点：¥" + _vm._s(_vm.errorData.cashRebate))])]) : _vm._e()])]), _c('div', {
          staticClass: "errorMsg-btns"
        }, [_c('b-button', {
          attrs: {
            "type": "is-danger",
            "rounded": ""
          },
          on: {
            "click": function click($event) {
              return _vm.toBack(1, _vm.errorData.orderNumber, 1);
            }
          }
        }, [_vm._v("重新选购")]), _vm.errorData.isCondition == 0 ? _c('b-button', {
          attrs: {
            "type": "is-info",
            "rounded": ""
          },
          on: {
            "click": function click($event) {
              return _vm.enterOrder(_vm.errorData.orderNumber);
            }
          }
        }, [_vm._v("确认订单")]) : _vm._e(), _vm.errorData.isCondition == 1 ? _c('b-button', {
          attrs: {
            "type": "is-info",
            "rounded": "",
            "disabled": "true"
          }
        }, [_vm._v("确认订单")]) : _vm._e()], 1)])])]) : _vm._e()])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "trap-focus": "",
      "aria-role": "dialog",
      "aria-label": "Example Modal",
      "close-button-aria-label": "Close",
      "aria-modal": "",
      "can-cancel": true,
      "has-modal-card": true,
      "destroy-on-hide": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "arrears-dialog",
          staticStyle: {
            "border-radius": "10px"
          }
        }, [_c('div', {
          staticClass: "proBox1"
        }, [_c('div', {
          staticClass: "proBox-title"
        }, [_c('img', {
          attrs: {
            "src": require("../../../assets/images/img_adorn.png"),
            "alt": ""
          }
        }), _c('span', [_vm._v(_vm._s(_vm.arrearsData.debitFlag))])]), _vm.arrearsData.currentSumDebit > 0 ? _c('div', {
          staticClass: "proBox-msg"
        }, [_c('p', [_vm._v("截止当前，"), _c('span', {
          staticStyle: {
            "color": "#E5432E"
          }
        }, [_vm._v("您已累计欠款" + _vm._s(_vm.arrearsData.currentSumDebit) + "元，")])]), _c('p', [_vm._v("为不影响您正常下单，请立即还款。")])]) : _vm._e(), _vm.arrearsData.currentSumDebit == 0 ? _c('div', {
          staticClass: "proBox-msg"
        }, [_c('p', [_vm._v("请联系业务员调整授信额度。")])]) : _vm._e(), _vm.arrearsData.currentSumDebit > 0 ? _c('div', {
          staticClass: "proBox-btn",
          on: {
            "click": _vm.nowArrears
          }
        }, [_c('span', [_vm._v("立即还款")])]) : _vm._e()])])];
      }
    }]),
    model: {
      value: _vm.userArrears,
      callback: function callback($$v) {
        _vm.userArrears = $$v;
      },
      expression: "userArrears"
    }
  }), _c('PageFooter'), _vm.showtx ? _c('div', {
    staticClass: "txyhsyye"
  }, [_c('div', {
    staticClass: "txyhsyye-t"
  }, [_c('div', {
    staticClass: "txyhsyye-t1"
  }, [_vm._v("提示")]), _c('div', {
    staticClass: "txyhsyye-t2"
  }, [_vm._v(" 本次订单可用抵扣余额：" + _vm._s(_vm._f("NumFormat")(Number(_vm.balanceAmount))) + "元，是否使用？ ")]), _c('div', {
    staticClass: "txyhsyye-t3",
    on: {
      "click": _vm.submit_nouse
    }
  }, [_vm._v("不使用")]), _c('div', {
    staticClass: "txyhsyye-t4",
    on: {
      "click": _vm.submit_use
    }
  }, [_vm._v("使用")])])]) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('span', [_vm._v("*")]), _c('span', [_vm._v("单位名称")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('span', [_vm._v("*")]), _c('span', [_vm._v("单位编码")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('span', [_vm._v("*")]), _c('span', [_vm._v("单位地址")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "product-columns"
  }, [_c('p', {
    staticClass: "columns-product"
  }, [_vm._v("商品")]), _c('p', {
    staticClass: "columns-price"
  }, [_vm._v("单价")]), _c('p', {
    staticClass: "columns-num"
  }, [_vm._v("数量")]), _c('p', {
    staticClass: "columns-subtotal"
  }, [_vm._v("小计")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "list-columns"
  }, [_c('p', [_vm._v("商品名称")]), _c('p', [_vm._v("规格")]), _c('p', [_vm._v("生产厂家")]), _c('p', [_vm._v("效期")]), _c('p', [_vm._v("单价（元）")]), _c('p', [_vm._v("包装")]), _c('p', [_vm._v("数量")]), _c('p', [_vm._v("优惠（元）")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("./image/phone.png")
    }
  }), _vm._v("是否需要电话开票服务 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("./image/yue.png")
    }
  }), _vm._v(" 余额抵扣 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    staticStyle: {
      "width": "30px"
    },
    attrs: {
      "src": require("./image/yue.png")
    }
  }), _vm._v(" 优惠券 ")]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    attrs: {
      "headerTit": "订单结算",
      "inputWidth": "370"
    }
  }), _c('div', {
    staticClass: "pay-ment-box"
  }, [_vm.type === 'bill' ? _c('div', {
    staticClass: "total-amount-box"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('img', {
    attrs: {
      "src": require("./image/icon_submit.png"),
      "alt": ""
    }
  }), _c('div', [_c('p', [_vm._v("账单支付！")]), _c('p', [_c('span', [_vm._v("账单编号：" + _vm._s(_vm.bill.data.documentNo))]), _c('span', [_vm._v("账单周期：" + _vm._s(_vm.bill.data.billCycle))])])])]), _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("总计")]), _c('p', [_vm._v("¥")]), _c('p', [_vm._v(_vm._s(_vm.bill.data.accountsPayable))])])]) : _c('div', {
    staticClass: "total-amount-box"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('img', {
    attrs: {
      "src": require("./image/icon_submit.png"),
      "alt": ""
    }
  }), _c('div', [_c('p', [_vm._v("订单提交成功，请尽快支付！")]), _c('p', {
    staticClass: "cd"
  }, [_vm._v(" 请在 "), _vm.countDown.show ? _c('span', [_c('countdown', {
    attrs: {
      "value": _vm.countDown.value,
      "format": "h时m分s秒"
    },
    on: {
      "stop": _vm.countDownStopHandler
    }
  })], 1) : _vm._e(), _vm._v(" 内完成支付，否则订单会被自动取消 ")]), _c('p', [_c('span', [_vm._v("订单编号：" + _vm._s(_vm.order.data.orderNumber))]), _c('span', [_vm._v("商品名称：" + _vm._s(_vm.order.data.productName))]), _c('span', [_vm._v("下单时间：" + _vm._s(_vm.order.data.orderAt))])])])]), _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("总计")]), _c('p', [_vm._v("¥")]), _c('p', [_vm._v(_vm._s(_vm.order.data.realPayAmount))])])]), _c('div', {
    staticClass: "pay-type-box"
  }, [_c('div', {
    staticClass: "button-box"
  }, [_c('p', {
    on: {
      "click": _vm.toPayModal
    }
  }, [_vm._v("前往支付")])])])]), _vm.qrCodeModal.show ? _c('div', {
    staticClass: "model-box"
  }, [_c('div', {
    staticClass: "center-box"
  }, [_c('div', {
    staticClass: "box-header"
  }, [_c('p', [_vm._v("微信/支付宝支付")]), _c('img', {
    attrs: {
      "src": require("./image/icon_close.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closePayModal
    }
  })]), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "box-pay"
  }, [_c('div', {
    staticClass: "qrcode-countdown"
  }, [_vm.qrCodeModal.regetShow ? _c('span', [_vm._v(" 二维码已过期，点击 "), _c('i', {
    on: {
      "click": _vm.regetQrCode
    }
  }, [_vm._v("刷新")]), _vm._v(" 重新获取二维码。 ")]) : _c('span', [_vm._v(" 距离二维码过期还剩 "), _c('b', [_vm._v(_vm._s(_vm.qrCodeModal.overtime))]), _vm._v(" 秒，过期后请重新获取二维码。 ")])]), _c('div', {
    staticClass: "code-box"
  }, [_c('img', {
    attrs: {
      "alt": "二维码",
      "src": _vm.qrCodeModal.qrCodeUrl
    }
  }), _vm.qrCodeModal.regetShow ? _c('span', {
    staticClass: "reget",
    on: {
      "click": _vm.regetQrCode
    }
  }, [_c('i', [_vm._v("点击重新获取二维码")])]) : _vm._e()]), _c('p', [_vm._v(" 请使用微信或支付宝扫一扫 扫码支付 ")])]), _c('div', {
    staticClass: "title-box"
  }, [_c('p', [_vm._v(" 交易金额 "), _c('span', [_vm.type === 'bill' ? [_vm._v(" " + _vm._s(_vm.bill.data.accountsPayable) + " ")] : [_vm._v(_vm._s(_vm.order.data.realPayAmount))]], 2), _vm._v(" 元 ")])])])])]) : _vm._e(), _c('PageFooter')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };